#login-wrapper {
  height: 100vh;
  background-color: #0580c4;
  margin: 0;
}

.login-container {
  padding-top: 10vh;
}

.login-card {
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
}

.card-body {
  padding: 2rem 0;
}
